import React from "react"
import PropType from "prop-types"
import Helmet from "react-helmet"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Title from "../components/Title.tsx"
import Sticky from "../components/Sticky.tsx"
import SEO from "../components/Seo.js"
import { VideoCarousel } from "../components/VideoCarousel.tsx"

// const stripHtml = (html) => {
//   if (typeof window !== 'undefined') {
//     const doc = new DOMParser().parseFromString(html, 'text/html');
//     return doc.body.textContent || '';
//   }
//   return html;
// };

let VIDEOS = []

export default class PostTemplate extends React.Component {
  constructor() {
    super()

    this.propTypes = {
      data: PropType.shape({}).isRequired,
    }
  }

  render() {
    const {
      data: { wordpressWpTeam: post, submenu },
    } = this.props
    return (
      <Layout>
        <Helmet
          title={post.yoast.title}
          meta={[
            {
              name: "description",
              content: post.yoast.metadesc,
            },
          ]}
          link={[{ rel: "canonical", content: post.yoast.canonical }]}
        />
        <SEO
          title={post.yoast.title || post.acf.title_seo || post.title}
          description={
            post.yoast.metadesc || post.acf.paragraph_seo || post.excerpt
          }
          pathname={post.slug}
          article
        />
        <div className="no-sticky-padding">
          <Sticky
            backgroundImage={post.featured_media.source_url}
            bgCentered={true}
          >
            {post.featured_media && (
              <Img
                src={post.featured_media.localFile.childImageSharp.sizes.src}
                sizes={post.featured_media.localFile.childImageSharp.sizes}
                className="img-fluid featured_media team-individual-image"
                alt={post.title}
              />
            )}
            <article className="team-individual">
              <a
                href="#"
                onClick={() => {
                  window.history.back()
                }}
                className="back-button"
              >
                <span />
                Back
              </a>
              <Title
                title={post.acf.title_seo ? post.acf.title_seo : post.title}
                subtitle={post.acf.subtitle_seo}
                paragraphs={post.acf.paragraph_seo}
                format="light"
              />
              <div className="post">
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
                {post.acf.team_videos && post.wordpress_id === 349 && (
                  <div className="videos">
                    {post.acf.team_videos.map(item => {
                      VIDEOS.push(item)
                    })}
                    <h2>Videos</h2>
                    <VideoCarousel items={VIDEOS} />
                  </div>
                )}
              </div>
            </article>
          </Sticky>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    submenu: allWordpressWpApiMenusMenusItems(
      filter: { slug: { eq: "about" } }
    ) {
      edges {
        node {
          items {
            title
            url
            target
          }
        }
      }
    }
    wordpressWpTeam(id: { eq: $id }) {
      wordpress_id
      title
      yoast {
        title
        metadesc
        canonical
      }
      acf {
        title_seo
        subtitle_seo
        paragraph_seo
        team_videos {
          videoID
          title
          url
        }
      }
      content
      date(formatString: "DD, MMM YYYY")
      slug
      id
      modified
      featured_media {
        source_url
        localFile {
          childImageSharp {
            id
            sizes(maxWidth: 2000) {
              src
              ...GatsbyImageSharpSizes
            }
            resolutions(width: 1000) {
              width
              height
              src
            }
          }
        }
      }
      slug
    }
  }
`
