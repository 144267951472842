import React, { FunctionComponent, useState, ReactElement } from "react"
import Carousel from "@brainhubeu/react-carousel"
import SimpleModal from "./SimpleModal"

import "./VideoCarousel.scss"

import "@brainhubeu/react-carousel/lib/style.css"
import { isMobileOnly, isTablet } from "react-device-detect"
import classNames from "classnames"
import { IVideoCarouselItem } from "../types"

export const VideoCarousel: FunctionComponent<{
  items: IVideoCarouselItem[]
}> = ({ items }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [videoID, setVideoID] = useState<string>("")

  const handleOpenModal = (id: string): void => {
    setShowModal(true)
    setVideoID(id)
  }
  return (
    <div
      className={classNames("video-carousel", {
        mobile: isMobileOnly,
        tablet: isTablet,
      })}
    >
      <Carousel
        breakpoints={{
          500: {
            slidesPerPage: 1,
            slidesPerScroll: 1,
          },
          1024: {
            slidesPerPage: 3,
            slidesPerScroll: 1,
          },
        }}
        slidesPerPage={3}
        slidesPerScroll={1}
        offset={10}
        infinite
        arrows
      >
        {items.map((item, index) => (
          <div className="item" key={index}>
            <div
              className="image"
              style={{
                backgroundImage: `url(//img.youtube.com/vi/${
                  item.videoID
                }/hqdefault.jpg)`,
              }}
              onClick={() => {
                handleOpenModal(item.videoID)
              }}
            />
            <h5>
              <a href={item.url} target="_blank">
                {item.title}
              </a>
            </h5>
          </div>
        ))}
      </Carousel>
      <SimpleModal
        open={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        buttonStyle={{
          color: "var(--color-white)",
          position: "relative",
          left: "0.5rem",
        }}
      >
        <iframe
          src={`https://www.youtube.com/embed/${videoID}?rel=0&amp;hd=1&amp;enablejsapi=1`}
          width="100%"
          height="349"
        />
      </SimpleModal>
    </div>
  )
}

export default VideoCarousel
